<script lang="ts" setup>
import type { ILeaseVariationDocument } from '@register'

const props = defineProps<{ amendment: ILeaseVariationDocument }>()
defineEmits<{ (event: 'create'): void }>()

const emptyMessage = computed(() => {
  return props.amendment.document
    ? `There is no amendment for the document:`
    : 'There is no amendment without document'
})
</script>

<template>
  <div class="grid grid-cols-1 p-2">
    <div class="flex flex-col justify-center rounded-md pb-8 pt-6">
      <h2
        class="flex max-w-[600px] flex-col self-center text-center text-base text-white"
      >
        {{ emptyMessage }}
        <span v-if="amendment.document" class="text-sm text-gray-300">
          {{ amendment.document.filename }}
        </span>
      </h2>
      <Icon
        name="empty-folder"
        class="mx-auto my-8 flex justify-center text-8xl text-gray-200"
      />
      <Button
        size="sm"
        color="secondary"
        class="flex self-center whitespace-nowrap"
        icon="plus"
        @click="$emit('create')"
      >
        Add an amendment
      </Button>
    </div>
  </div>
</template>
